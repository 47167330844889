* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', M PLUS Rounded 1c;
}

h1 {
  font-family: 'Anton';
}

body, html, #root {
  height: 100%;
}

.btn1 {
  background-color: #007bff; /* Button background color */
  color: #fff; /* Button text color */
  border: none; /* Remove button border */
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  cursor: pointer; /* Add pointer cursor on hover */
  border-radius: 5px; /* Add rounded corners */
}

.btn1:hover {
  background-color: #0056b3; /* Change background color on hover */
}
/* Add this to the component's CSS file */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.home,
.insemination,
.milk-production,
.skin-disease,
.tracking-system,
.stress-prediction{
  display: flex;
  height: 90vh;
  /* align-items: center; */
  /* justify-content: center; */
  /* font-size: 3rem; */
}

.home {
  background-image: url('../public/images/img-3.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.insemination {
 
  /* background-position: center; */
  /* background-size: cover; */
  /* background-repeat: no-repeat; */
  color: #fff;
  font-size: 100px;
}

.milk-production {
  background-image: url('././components/pages/images/img-3.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  /* font-size: 100px; */
}

.skin-disease {
  background-image: url('../public/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}


.stress-prediction {
  background-image: url('../public/images/img-7.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}


.tracking-system {
  background-image: url('../public/images/img-6.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}


.wrapper {
  padding: 5px 20px;
}

.wrapper fieldset {
  margin: 20px 0;
}

.form-group{
  align-items: center;
}


.form-container {
  display: grid;
  place-items: center;

}

form {
  width: 50%;
}

.results{
  background-color: #f5f5f5; border: 1px solid #ccc; border-radius: 5px; padding: 20px; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
              max-width: 600px;
              margin: 0 auto;
}
.danger{
  /* margin-bottom: 10px;padding: 5px;background-color: #fff;border: 1px solid #ddd;border-radius: 3px;display: flex;justify-content: space-between; */font-weight: bold;
}

.safe{
  /* margin-bottom: 10px;padding: 5px;background-color: #fff;border: 1px solid #ddd;border-radius: 3px;display: flex;justify-content: space-between;font-weight: bold; */font-weight: bold;
}


.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.image-container{
  text-align: center; 
  
}
.image-holder{
  width: 512px;
  height: 512px;
}

#mapContainer{
  text-align: center;
}

.btn1{

  margin-right: 20px;
  
  }


  body{
    background-image: url('././components/pages/images/back.jpg');
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  label{
    font-weight: bolder;
  }

