.custom-card {
    padding: 40px;
    margin: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f8f9fa; /* Light gray background color */
  }
  
  .luxury-title {
    font-size: 2rem;
    font-weight: bold;
    color: #333; /* Dark gray title color */
  }
  
  .selected-image {
    max-width: 100%;
    margin-top: 20px;
    border: 2px solid #17a2b8; /* Teal border around the selected image */
    border-radius: 10px; /* Rounded corners for the image */
  }
  
  /* Style for the Predict button */
  .custom-btn {
    background-color: #28a745; /* Green background color */
    border-color: #28a745; /* Green border color */
  }
  
  .custom-btn:hover {
    background-color: #218838; /* Darker green on hover */
  }
  
  /* Style for the Upload File input */
  .custom-file-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  
  .custom-file-input::before {
    content: 'Upload'; /* Custom text for the Upload button */
    display: inline-block;
    background: linear-gradient(to top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  
  .custom-file-input:hover::before {
    border-color: black;
  }
  
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
  
  /* Add any additional custom styles as needed */  