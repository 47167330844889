.footer-container {
  background-color: #003366; /* Background color of the footer */
  color: #fff; /* Text color */
  padding: 4rem 0; /* Adjust padding as needed */
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: auto;
  padding: 0 24px;
}

.footer-logo {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.footer-logo i {
  margin-left: 8px;
}

.website-rights {
  font-size: 14px;
}

.social-icons {
  display: flex;
  align-items: center;
}

.social-icon-link {
  font-size: 24px;
  margin-right: 16px;
  color: #fff;
  transition: transform 0.3s;
}

.social-icon-link:hover {
  transform: scale(1.2);
}

.footer-subscription {
  margin-top: 2rem; /* Adjust margin as needed */
  text-align: center;
}

.footer-subscription-heading {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.footer-subscription-text {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.input-areas {
  display: flex;
  justify-content: center;
}

.footer-input {
  padding: 8px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
}

/* Customize the subscribe button style as needed */