.custom-form-group {
    margin-bottom: 20px; /* Add margin at the bottom of the form group */
  }
  
  .custom-label {
    font-size: 18px; /* Adjust the font size as needed */
    font-weight: bold; /* Add bold font weight */
    color: #333; /* Adjust the color as needed */
    margin-bottom: 10px; /* Add some space below the label */
  }
  
  .radio-buttons {
    display: flex;
    justify-content: center;
  }
  
  .custom-radio {
    margin-right: 10px;
  }   