.navbar {
  background-color: #003366; /* Change the background color as needed */
}

.navbar-brand {
  color: #fff; /* Change the text color as needed */
}

.navbar-nav .nav-link {
  color: #fff; /* Change the text color as needed */
}

.navbar-nav .nav-link:hover {
  color: #ccc; /* Change the hover color as needed */
}

.menu-icon {
  display: none; /* Hide the menu icon for larger screens */
}

@media screen and (max-width: 960px) {
  .menu-icon {
    display: block; /* Show the menu icon for smaller screens */
    cursor: pointer;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 70px; /* Adjust as needed */
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #333; /* Change the background color as needed */
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item {
    margin: 1rem 0;
    text-align: center;
    width: 100%;
  }

  .nav-links {
    text-align: center;
    width: 100%;
    display: table;
    padding: 10px;
  }

  .nav-links:hover {
    background-color: #444; /* Change the hover background color as needed */
    border-radius: 5px; /* Add border-radius as needed */
  }
}